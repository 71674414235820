.message {
  font-size: 0.8em;
  line-height: 1.2em;
  padding: 0 0 0.75em;

  &.error {
    color: var(--color-error);
  }

  &.success {
    color: var(--color-success);
  }

  &.center {
    text-align: center;
  }

  &.main {
    font-size: 1rem;
    margin-bottom: 1em;
    font-style: normal;
  }

  a {
    text-decoration: underline;
  }
}
