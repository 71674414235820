.lot {
  @apply relative;
  border: 1px solid var(--color-primary);
  border-radius: 0.375rem;

  .lot__heading {
    @apply w-full inline-block;
    border-top-right-radius: 0.25rem;
    color: white;

    & > div {
      @apply relative w-full px-2 py-1;

      span {
        position: relative;
        z-index: 10;
      }

      &::before {
        @apply absolute top-0 left-0 w-full h-full;
        content: "";
        background: var(--color-primary);
        opacity: 0.75;
        z-index: 0;
      }

      .lot__dispo {
        text-transform: capitalize;
      }
    }
  }

  &.lot--selected {
    hr {
      background: var(--color-primary);
      opacity: 0.45 !important;
      z-index: -1;
    }
  }

  .lot__body {
    @apply flex flex-wrap items-center;

    input {
      @apply hidden;

      &:checked {
        & + hr {
          background: var(--color-primary);
          opacity: 0.1;
          z-index: -1;
        }
      }
    }

    & > svg {
      @apply w-4 mr-4;
    }

    & > div > svg {
      @apply w-8 mr-4;
    }

    hr {
      @apply absolute top-0 left-0 w-full h-full;
      border: none;
    }

    & > div {
      z-index: 1;
    }

    &.lot--notfull > div {
      width: 100%;
    }

    .lot__details {
      @apply w-1/2 flex items-center mt-2;

      svg {
        @apply w-8 mr-2 shrink-0;
      }
    }

    .button {
      margin-bottom: 0 !important;
    }
  }
}
