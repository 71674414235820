$colors: (
  "primary": var(--color-primary),
  "texts": var(--color-texts),
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
  .bg-light-#{$name} {
    background-color: $color;
    opacity: 0.1;
  }
  .text-#{$name} {
    color: $color;
  }
  .text-light-#{$name} {
    color: $color;
    opacity: 0.1;
  }
  .fill-#{$name} {
    fill: $color;
  }
  .border-#{$name} {
    border: 1px solid $color;
  }
  .fill-light-#{$name} {
    fill: $color;
    opacity: 0.1;
  }
  .stroke-plain {
    fill: none;
    stroke-width: 16;
    stroke-miterlimit: 10;
  }
  .stroke-small {
    fill: none;
    stroke-width: 2.5;
  }
  .stroke-medium {
    fill: none;
    stroke-width: 4;
  }
  .stroke-dashed {
    fill: none;
    stroke-miterlimit: 10;
    stroke-dasharray: 7.9576, 7.9576;
  }
  .stroke-#{$name} {
    stroke: $color;
  }
  .stroke-light-#{$name} {
    stroke: $color;
    opacity: 0.1;
  }
}
