.videoSlider {
  .start,
  .end {
    position: absolute;
  }

  .start {
    left: 32px;
    transform: translateX(-50%);
  }

  .end {
    right: 32px;
    transform: translateX(50%);
  }

  .current {
    font-size: 4rem;
    line-height: 1rem;
  }
}
