form,
.form,
.button-parent {
  label,
  input,
  textarea,
  button,
  .button {
    font-family: "Albert Sans", sans-serif;
    display: block;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.25em;
  }

  ::placeholder {
    color: var(--color-primary);
    opacity: 0.8;
  }

  :-ms-input-placeholder {
    color: var(--color-primary);
  }

  ::-ms-input-placeholder {
    color: var(--color-primary);
  }

  input,
  input:hover,
  input:focus,
  textarea,
  textarea:hover,
  textarea:focus {
    border: 2px solid var(--color-primary);
    outline: none;
    padding: 0.5em;
  }

  button,
  .button {
    text-transform: uppercase;
    background: var(--color-primary) !important;
    color: white;
    transition: 0.25s;
    padding: 0.5em;

    &:hover {
      opacity: 0.75;
    }

    &.button--secondary {
      background: white !important;
      border: 2px solid var(--color-primary) !important;
      color: var(--color-primary);
    }
  }
}
