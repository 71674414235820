.searchForm {
  & > label {
    margin-bottom: 0.5rem;
  }

  input {
    color: hsl(0, 0%, 20%);
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: hsl(0, 0%, 50%);
  }

  ::-ms-input-placeholder {
    color: hsl(0, 0%, 50%);
  }

  .inputParent {
    margin-bottom: 1rem;
    outline: none !important;

    & > div {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      cursor: pointer !important;

      & > div {
        border: 2px solid var(--color-primary) !important;
        outline: none !important;
        box-shadow: none !important;
        padding: 0.1em;

        path {
          fill: var(--color-primary);
        }

        span {
          background: var(--color-primary);
        }
      }
    }
  }

  .selectParent {
    svg {
      width: 20px !important;
    }
  }

  & > button {
    margin-top: 2rem;
  }
}
