.videoRotary {
  button  {
    border-style: solid;
    border-width: 2px;
  }

  .north,
  .east,
  .south,
  .west {
    position: absolute;
    text-align: center;
    display: block;
  }

  .north,
  .south {
    left: 0;
    width: 100%;
    height: 30px;
  }

  .east,
  .west {
    top: 0;
  }

  .videoRotary__joystick {
    width: 250px;
    height: 250px;

    &.videoRotary__joystick--coordinates {
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        background: var(--color-primary);
        width: 20px;
        height: 2px;
      }
    }

    &::before {
      left: -10px;
    }

    &::after {
      right: -10px;
    }

    .joystick__base {
      width: 95px;
      height: 95px;
    }

    .joystick__inner {
      width: 120px;
      height: 120px;
      box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.21);
    }
  }

  .joystick {
    .joystick__arrow {
      position: absolute;
      width: 20px;
    }

    .joystick__cardinal {
      .joystick__arrow {
        position: absolute;
        width: 20px;
        left: calc(50% - 10px);
        top: -70px;
      }
    }

    .joystick__coordinates {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .east,
    .west {
      width: 100%;
      height: 30px;
    }

    .north {
      top: -40px;
    }

    .east {
      top: calc(50% - 15px);
      right: calc(-50% - 25px);
      transform: rotate(90deg);
    }

    .south {
      bottom: -40px;
      transform: rotate(180deg);
    }

    .west {
      top: calc(50% - 15px);
      left: calc(-50% - 25px);
      transform: rotate(270deg);
    }
  }

  .videoRotary__content {
    position: relative;

    .east,
    .west {
      top: 0;
      height: 100%;
      width: 30px;
      writing-mode: vertical-lr;
      text-orientation: upright;
    }

    .north {
      top: -30px;
    }

    .east {
      right: -30px;
    }

    .south {
      bottom: -30px;
    }

    .west {
      left: -25px;
    }
  }

  .joystick__orientation {
    position: relative;
    margin-bottom: 3rem;
    height: 120px;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0) 75%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .joystick__arrow {
      left: calc(50% - 10px);

      &.joystick__arrow--top {
        top: 0;
      }

      &.joystick__arrow--bottom {
        bottom: 0;
        transform: rotate(180deg);
      }
    }

    .orientation__barre {
      @apply absolute flex flex-wrap;
      width: 200%;
      top: 30px;

      &.orientation__barre--0 {
        left: -150%;
      }
      &.orientation__barre--1 {
        left: 50%;
      }

      .barre__coordinate {
        width: 25%;
        position: relative;

        .coordinate__name {
          display: inline-block;
          transform: translateX(-50%);
          text-transform: uppercase;
          padding-top: 2.5rem;
        }

        .coordinate__init,
        .coordinate__lines svg {
          position: absolute;
          width: 2px;
        }

        .coordinate__init {
          top: 0;
          left: 0;
        }

        .coordinate__lines {
          svg {
            top: 10px;
            @for $i from 1 through 9 {
              &:nth-child(#{$i}) {
                left: calc(100% / 10 * $i);
              }
            }
          }
        }
      }
    }
  }
}
