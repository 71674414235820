@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@100..900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
b,
.button {
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
}

i {
  font-style: normal;
}
