.chapter {
  cursor: pointer;
  opacity: 1;
  transition: 0.25s;

  &.chapter--active {
    opacity: 0.6;
  }

  i {
    display: inline-block;
    padding-left: 0.25rem;
  }
}
