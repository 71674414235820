.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: white;
  border-bottom: 2px solid var(--color-primary);
  z-index: 999;

  img,
  svg {
    height: 40px;
  }

  .navigation__logout {
    width: 40px;
  }
}
