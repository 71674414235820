.available {
  &.available--true {
    color: green;
  }

  .available__list {
    font-size: 3rem;

    p {
      font-size: 1.5rem;
      font-style: normal;
    }
  }
}
