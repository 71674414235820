.element {
  hr {
    transition: 0.25s;
  }

  &:hover {
    hr {
      opacity: 0.25;
    }
  }

  &.selected {
    hr {
      opacity: 0.4;
    }
  }

  .element__content {
    width: 100%;

    &.element__content--has-icon {
      width: calc(100% - 2rem);
    }

    p > svg {
      display: inline-block;
      width: 20px;
      margin-left: 10px;
    }
  }
}
