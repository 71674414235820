.alert {
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);

  .alert__inner {
    width: 80%;
  }
}
