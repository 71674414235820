@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./forms.scss";
@import "./animations.scss";

html,
body {
  margin: 0;
  color: var(--color-texts);
  font-family: "Albert Sans", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 100%;
  .plain {
    opacity: 1;
  }

  .transparent {
    opacity: 0;
  }

  a,
  a:hover,
  a:visited {
    color: inherit;
    text-decoration: none;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: transparent;
  }

  ul,
  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    cursor: pointer;
  }

  hr {
    border: none !important;
  }

  main {
    height: calc(100vh - 80px);
    margin-top: 80px;
    overflow-y: auto;

    & > div,
    & > footer {
      width: 100%;
    }

    .main__container {
      padding: 0px 1.25em 40px;
    }

    &.main__off {
      height: calc(100vh - 132px);
      margin-top: 132px;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .organiser {
    & > div,
    & > footer {
      width: 100%;
    }
  }

  .icon {
    width: 20px;
  }

  .icon-large {
    width: 70px;
  }

  svg {
    width: 100%;
    height: auto;
  }

  .height-full {
    min-height: calc(100vh - 90px - 40px - 45px);
  }

  .main__off {
    .height-full {
      min-height: calc(100vh - 90px - 55px - 40px - 45px);
    }
  }

  .cursor--hide {
    cursor: none !important;

    a,
    button,
    input {
      cursor: none !important;
    }
  }
}
